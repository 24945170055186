import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Impressum = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title="Imprint" />
      <h1>{t("Imprint")}</h1>
      <h2>palmomedia</h2>
      <code>
        09669 Frankenberg/Sa.
        <br />
        <strong> support@my-follower.com</strong>
        <br />
        Vertreten durch: Michael Palmer
        <br />
        <br />
      </code>
      <h2>
        Did you find a <strong>bug?</strong>
      </h2>
      <code>
        <strong>bugs@my-follower.com</strong>
        <br />
        <br />
      </code>
      <h2>Disclaimer - rechtliche Hinweise Auskunfts- und Widerrufsrecht</h2>
      <p>
        Sie haben jederzeit das Recht, sich unentgeltlich und unverzüglich über
        die zu Ihrer Person erhobenen Daten zu erkundigen. Ebenfalls können Sie
        Ihre Zustimmung zur Verwendung Ihrer angegebenen persönlichen Daten mit
        Wirkung für die Zukunft widerrufen. Hierfür wenden Sie sich bitte an
        uns!
      </p>
      <p>
        Bitte beachten Sie unsere <a href="/privacy">Datenschutzerklärung</a>.
      </p>
      <p>
        Die Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
        angeforderter Werbung und Informationsmaterialien wird hiermit
        ausdrücklich untersagt. Ausgenommen hiervon sind bestehende
        Geschäftsbeziehungen bzw. es liegt Ihnen eine entsprechende Einwilligung
        von uns vor.
      </p>
    </Layout>
  );
};

export default Impressum;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
